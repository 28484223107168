
import React from "react";
import {Link} from "react-router-dom";
import { array } from "yup";

import logo from "../images/rt-logo.png";
import searchIcon from "../images/searchIcon.png";

import '../styles/topnav.css';


export default function Navbar(props) {
    return (
        <nav className="topnav">
            <Link to="/" className="logo-name">
                <img src={logo} className="nav-logo"></img>
                <p className="topnav-name">Research Tribune</p>
            </Link>
            <div className="topnav-searchbar">
                <input type="text" className="searchbar" placeholder="Search"></input>
                <button className="nav-searchbutton">
                    <img src={searchIcon} className="search-icon"></img>
                </button>
            </div>
            <div className="login-and-threedot">
            <button className="nav-login" onClick={()=>props.setLoginOpen("login")}>Log in</button>
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    &#x22EE;
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="#">Action</a></li>
                    <li><a class="dropdown-item" href="#">Another action</a></li>
                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>
            </div>
        </nav>
    )
}