const vidDatabase = [
    {
        vidId: 1,
        vidCategories:["Biology", "Medicine"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0001.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "When Hiccups Become Scary",
        vidUsername: "@AndrewHuberman",
        vidNViews: "1.3m"
    },
    {
        vidId: 2,
        vidCategories:["Physics", "Cosmology"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0002.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Our Universe May Have Invisible Walls",
        vidUsername: "@TheWorldofScienceCo",
        vidNViews: "114k"
    },
    {
        vidId: 3,
        vidCategories:["Physics", "Cosmology"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0003.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Cosmologically Light Doesn't Travel Fast",
        vidUsername: "@BrianCox",
        vidNViews: "331k"
    },
    {
        vidId: 4,
        vidCategories:["Biology", "Microbiology"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0004.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Microbiology Breakthroughs",
        vidUsername: "@MicroMan",
        vidNViews: "1.2m"
    },
    {
        vidId: 5,
        vidCategories:["Biology", "Genetics"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0005.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Controlling DNA with Electricity",
        vidUsername: "@superunique",
        vidNViews: "3.2m"
    },
    {
        vidId: 6,
        vidCategories:["Biology", "Genetics"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0006.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "DNA Editing Breakthrough - Enzymes through Snails",
        vidUsername: "@FutureFlux",
        vidNViews: "239k"
    },

    {
        vidId: 7,
        vidCategories:["Chemistry", "PhysicalChemistry"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0007.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Artificial Intelligence Creates an Actinium Atom",
        vidUsername: "@TheLibrary",
        vidNViews: "441k"
    },

    {
        vidId: 8,
        vidCategories:["Chemistry", "GreenChemistry"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0008.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Hydrogen Power: The Green Energy Breakthrough",
        vidUsername: "@ScienceBrief",
        vidNViews: "18k"
    },

    {
        vidId: 9,
        vidCategories:["Biology", "Neuroscience"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0009.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "AI Breakthrough in Thought-to-Image Reconstruction",
        vidUsername: "@AINewsOfficial",
        vidNViews: "1.91m"
    },
    {
        vidId: 10,
        vidCategories:["Biology", "Neuroscience"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0010.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "The Breakthrough of Silent Synapses in Brain Research",
        vidUsername: "",
        vidNViews: "644k"
    },
    {
        vidId: 11,
        vidCategories:["Physics", "QuantumMechanics"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0011.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Quantum Energy Transportation",
        vidUsername: "@upandatom",
        vidNViews: "93k"
    },
    {
        vidId: 12,
        vidCategories:["Physics", "SolidStatePhysics"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0012.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "The First Room Temperature Superconductor",
        vidUsername: "@freethink",
        vidNViews: "701k"
    },
    {
        vidId: 13,
        vidCategories:["Physics", "SolidStatePhysics"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0013.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Controlling Electron Flow Through Quantum Mechanics",
        vidUsername: "@CosmosDiscovery",
        vidNViews: "12k"
    },
    {
        vidId: 14,
        vidCategories:["Chemistry", "PhysicalChemistry"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0014.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "All Metal Fulerene",
        vidUsername: "@DailyScienceBreakthroughs",
        vidNViews: "139k"
    },
    {
        vidId: 15,
        vidCategories:["Chemistry", "PhysicalChemistry"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0015.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "AI Unlocks Ferroelectric Secrets",
        vidUsername: "@Science Brief",
        vidNViews: "941k"
    },

    {
        vidId: 16,
        vidCategories:["Chemistry", "PhysicalChemistry"],
        vidShort: true,
        vidAutoplay: false,
        vidSrc: "videos/short0016.mp4",
        vidPdfSrc:"images/hiccups.pdf",
        paperLink: "",
        paperDoi: "",
        vidTitle: "Perfluorocubane, aka The World's Smallest Box",
        vidUsername: "@ACSReactions",
        vidNViews: "552k"
    },
];

export default vidDatabase;