import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import TopNav from "./components/TopNav.js";
import SideNav from "./components/SideNav2.js";
import VideoPlayer from "./components/VideoPlayer.js";
import PopCat from "./components/PopCat.js";
import CatHomePage from "./components/CatHomePage.js";
import Login from "./components/Login.js";
import Signup from "./components/Signup.js";

import vidDatabase from "./vidDatabase";

export default function App() {


  //show video based on video of the day
  const vidOfDayIndex = 6;
  const vidOfDayProps = vidDatabase.filter((element) => (element.vidId===vidOfDayIndex))[0];


  const [loginOpen, setLoginOpen] = React.useState("");

  React.useEffect(() => {
    if (loginOpen=="signup" || loginOpen=="login") {
      document.body.style.overflow = "hidden";
      return () => {
          document.body.style.overflow = "scroll"
      }
    }
  }, [loginOpen]);


  return (

    <BrowserRouter>
      <TopNav setLoginOpen={setLoginOpen}/>
      <div onClick={()=>setLoginOpen("")}className="login-overlay" style={{display:loginOpen==""?"none":"block"}}>
      </div>
      <Signup loginOpen={loginOpen} setLoginOpen={setLoginOpen}/>
      <Login loginOpen={loginOpen} setLoginOpen={setLoginOpen}/>

      <div className="App" style={{display:"flex", pointerEvents: loginOpen?"none":"auto"}}>
        <SideNav />
        <Routes>
          <Route path="/" element={<VideoPlayer 
                                      vidSrc={vidOfDayProps.vidSrc} 
                                      vidPdfSrc={vidOfDayProps.vidPdfSrc}
                                      vidAutoplay={true}/>} />

          <Route path="/PopularCategories" element={<PopCat />} />

          <Route path="/:Category" element={<CatHomePage />} />

          <Route path="/testing" element={<Login />} />


        </Routes>
      </div>


    </BrowserRouter>

    
      

  )
}