import React from "react";


export default function Scholar({scholarImg, scholarName, scholarTag, scholarNumFollowers}) {

    return (
        <div className='scholar'>
            <div className='scholar-image'></div>
            <img className='circular--landscape' src={scholarImg} alt="Description" />
            <div className='scholar-name-box'>
                <h1 className='scholar-name'>{scholarName}</h1>
                <h1 className='scholar-name'>{scholarTag}</h1>
                <h1 className='scholar-name'>{`${scholarNumFollowers} followers`}</h1>
            </div>

        </div>

    )
}