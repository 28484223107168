import React from "react";
import {Link} from "react-router-dom";


//video element React component
import VideoElement from "./VideoElement.js";

//images for component
import right_arrow from "../images/right_arrow.png";
import left_arrow from "../images/left_arrow.png";


export default function Category({catLink, catTitle, catVideos}) {

    const [vidStartIndex, setVidStartIndex] = React.useState(0);

    const numVid = catVideos.length;

    /*Convert props of each video into VideoElements to be displayed on page*/
    const allCatVideosElements = catVideos.map((vidprops, index) => (
        <VideoElement key={index} {...vidprops} />))
    
    let catVideosShow = [];
    let numPushed = 0;
    for (let i=0; i<3; i++) {
      if (numPushed<numVid) {
        catVideosShow.push(allCatVideosElements[(vidStartIndex+i)%numVid])
      }
      numPushed += 1;
    }

    return (
        <div className="category">
            
          <Link to={catLink} className='category-title'>
            {catTitle}
          </Link>

          <div className='video-block'>
            {/*Left arrow for video carousel*/}
            <button className="cat-arrowbutton-left" onClick={()=>setVidStartIndex(val=>val-1)}><img src={left_arrow} className='left-arrow' ></img></button>
            
            {/*Video grid of videos*/}
            <div className='video-grid-container'>
                {catVideosShow}
            </div>
            
            {/*Right arrow for video carousel*/}
            <button className="cat-arrowbutton-right" onClick={()=>setVidStartIndex(val=>val+1)}><img src={right_arrow} className='right-arrow'></img></button>   
          </div>

        </div>
    )
}