import React from "react";
import Category from "./Category.js";


import vidExample from "../videos/short0001.mp4";

import vidDatabase from "../vidDatabase.js";



export default function PopCat() {

  //temporary default video properties for video element within Category component
  const vidCats = vidDatabase.map((element) => (element.vidCategories[0]));
  const categorySet = new Set(vidCats)
  const categorySetArray = Array.from(categorySet)


  //create category elements, grab proper videos from database
  const allCatElements = categorySetArray.map(
    (category) => 
      {
      return (<Category 
          catLink={`/${category}`}
          catTitle={category}
          catVideos={vidDatabase.filter((vid) => (vid.vidCategories[0]===category))}
        />)
      }
  );
  return (

      <div className='popcat-page'>

        {/* Title of entire page, popular categories*/}
        <h1 className="popcat-page-title">Popular Categories </h1>


        <div className="categories-container">
          {allCatElements}
        </div>

      </div>
      );
  };