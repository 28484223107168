
import React from "react";
import {Link} from "react-router-dom";

import dashboardIcon from "../images/dashboardIcon.png";
import dashboardIconClicked from "../images/dashboardIconClicked.png";
import exploreIcon from "../images/exploreIcon.png";
import exploreIconClicked from "../images/exploreIconClicked.png";
import communityIcon from "../images/communityIcon.png";
import communityIconClicked from "../images/communityIconClicked.png";
import trendingIcon from "../images/trendingIcon.png";
import trendingIconClicked from "../images/trendingIconClicked.png";
import downArrow from "../images/down-arrow.png";


export default function SideNav() {

    //options are dashboard, community, explore, and trending
    const [directClick, setDirectClick] = React.useState("trending");
    const [expandedExplore, setExpandedExplore] = React.useState(false);


    
    const categories = [
                        {catName:"Physics", link: "/"},
                        {catName:"Biology", link: "/"},
                        {catName:"Chemistry", link: "/"},
                        {catName:"Math", link: "/"},
                        {catName:"Medicine", link: "/"},
                        {catName:"Psychology", link: "/"},
                        {catName:"Economics", link: "/"},
                        {catName:"Botany", link: "/"},
                        {catName:"Evolution", link: "/"},
                        
                        ];

    const catHTML = categories.map((cat,index) => (

            <li>
                <Link className="sidenav-cat" key={index} to={`/${cat.catName}`}>{cat.catName}</Link>
            </li>

    ));


    return (
        <div className="side-nav">
            <div className="side-nav-direct-cont">

                <div className="side-nav-direct" onClick={() => setDirectClick("dashboard")}>
                    <img src={directClick=="dashboard"?dashboardIconClicked:dashboardIcon} className="side-nav-direct-icon" id="dashboard"></img>
                    <p className={(directClick=="dashboard")?"side-nav-direct-title-clicked":"side-nav-direct-title"}>Dashboard</p>
                </div>

                <div className="side-nav-direct" onClick={() => setDirectClick("community")}>
                    <img src={directClick=="community"?communityIconClicked:communityIcon} className="side-nav-direct-icon" id="community"></img>
                    <p className={(directClick=="community")?"side-nav-direct-title-clicked":"side-nav-direct-title"}>My Communities</p>
                </div>

                <div className="side-nav-direct d-flex align-items-center" onClick={() => setDirectClick("explore")}>
                    <img src={directClick=="explore"?exploreIconClicked:exploreIcon} className="side-nav-direct-icon" id="explore"></img>
                    <p className={(directClick=="explore")?"side-nav-direct-title-clicked":"side-nav-direct-title"}>Explore</p>
                        <button className='dropdown-button' id='expandedExplore' onClick={()=>setExpandedExplore(prev=>!prev)}></button>
                </div>
                <div style={{display:expandedExplore?'inline-block':'none'}}>
                    <ul className='dropdown-format'>
                        {catHTML}
                    </ul>
                </div>

                

                <div className="side-nav-direct" onClick={() => setDirectClick("trending")}>
                    <img src={directClick=="trending"?trendingIconClicked:trendingIcon} className="side-nav-direct-icon" id="trending"></img>
                    <p className={(directClick=="trending")?"side-nav-direct-title-clicked":"side-nav-direct-title"}>Top Trending</p>
                </div>
            </div>

            <hr style={{border:"1px solid black"}}/>

        </div>
    )
}