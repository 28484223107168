import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import "./styles/body.css";
import "./styles/topnav.css";
import "./styles/sidenav2.css";
import "./styles/videoplayer.css";
import "./styles/popcat.css";
import "./styles/login.css";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);