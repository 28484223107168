import React from "react";

/*Element of video with video info below*/
export default function VideoElement({vidAutoplay, vidSrc, vidTitle, vidUsername, vidNViews}) {
    const videoSource = require(`../${vidSrc}`)
    return (
        <div className='video-grid-element'>
            <video className="embed-responsive-item" autoplay={vidAutoplay} id="video-short-embed" src={videoSource} controls></video>
            <div className='video-title-container'>
                <p className='video-title'>{vidTitle}</p>
                <div className='video-title-username-flexbox'>
                    <div className='video-username'> {vidUsername}</div> 
                    <div className='video-views'>{vidNViews}</div>
                </div>
            </div>
        </div>
    )
}