import React from "react";

import Scholar from "./Scholar.js";

export default function PopScholars({scholarsProps}) {
    const scholarElements = scholarsProps.map(
        (props, index) => 
        (<Scholar key={index} {...props} />)
    );
    return (
        <div className='pop-scholars'>
            <h1 className='pop-scholars-header'>Popular Scholars</h1>

            <div className='pop-scholars-grid'>
                {scholarElements}
            </div>
        </div>
    )
}