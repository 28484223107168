import React from "react";
import {Link} from "react-router-dom";

export default function TableOfContents({subCatArray}) {
    const contentElements = subCatArray.map(
        (element, index) => (
            <li style={{color:"black"}}><Link to="/" key={index}>{element}</Link></li>
        )
    );
    
    return (
        <div className='tocontents'>
            <h1 className='toc-header'>Table of Contents</h1>

            <ul className='toc-list'>
                {contentElements}
            </ul>
        </div>
    );
}