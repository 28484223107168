import React from "react";
import "../styles/videoplayer.css";


export default function VideoPlayer({vidSrc, vidPdfSrc, vidAutoplay}) {
    
    //import video sources safely
    let videoSource = require(`../${vidSrc}`);
    let videoPdfSource = require(`../${vidPdfSrc}`)


    return (
        <div className="videoplayer">

            <div className="video-container">
                <video class="embed-responsive-item" autoplay={vidAutoplay} id="video-embed" src={videoSource} controls></video>
            </div>

            <div className="abstract-comments-container">
                <embed 
                className="abstract"
                src={videoPdfSource} 
                width="100%"
                height="100%"
                ></embed>
                <div className="vp-comments">
                    comments
                </div>

            </div>
            
        </div>
    );
}

