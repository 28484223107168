import React from "react";
import { useParams } from "react-router-dom";

import Category from "./Category.js";
import PopScholars from "./PopScholars.js";
import TableOfContents from "./TableOfContents.js";

import vidDatabase from "../vidDatabase.js";

import '../styles/biology.css';
import huberman from "../images/huberman.png";



export default function CatHomePage() {

    const catDescriptions = {
        Biology:'The study of life from microscopic and macroscopic levels...',
        Physics:'The study of movement, matter, and energy...',
        Chemistry:'The study of elements and their properties...',
        Math:'The study of quantitative analysis...',
        Medicine:'The study of human disorders and their remedies...',
        Psychology:'The study of human behavior...',
        Economics:'The study of social behavior with regards to money and spending...',
        Botany:'The study of plants and their properties...',
        Evolution:'The study of how organisms have changed over millions of years...'
    }

    //get category from link parameter
    const params = useParams();
    const category = params.Category;


    //temporary default video properties for video element within Category component
    const videosProps = vidDatabase.filter((element)=>(element.vidCategories[0]==category));


    //Get all subcategories
    const subCatSet = new Set(videosProps.map((props)=>(props.vidCategories[1])))
    const subCatArray = Array.from(subCatSet)

    //create category elements, grab proper videos from database
    const subCatElements = subCatArray.map(
        (subCat) => 
            
            {
                return (
                    <Category 
                        catLink={`/${category}/${subCat}`}
                        catTitle={subCat}
                        catVideos={vidDatabase.filter((vid) => (vid.vidCategories[0]===category &&
                                                                vid.vidCategories[1]===subCat))}
                    />
                )
            }
    );


    //temporary default props for a given scholar for page
    const scholarProps = {
        scholarImg: huberman,
        scholarName: "Andrew Huberman",
        scholarTag: "@huberman",
        scholarNumFollowers: "1.3m"
    };

    //temporary default props for many scholars to display under popular scholars
    const scholarsProps = [scholarProps, scholarProps, scholarProps, scholarProps];

    //temporary default props for table of contents


    return (
        <div className='cat-page'>
            
            {/*Title container for cat page */}
            <div className='catpage-title-container'>
                <h1 className='catpage-header'>{category}</h1>
                <p className='cat-description'>{catDescriptions[category]}</p>
            </div>

            <div className='popschol-tocontents'>
                <PopScholars scholarsProps={scholarsProps} />
                <TableOfContents subCatArray={subCatArray}/>
            </div>

            <div className='subcats'>
                {subCatElements}     
            </div>
                {/* Popauth felt a bit repetitive with popular scholars maybe? 
                <div className='popauth'>
                    <h1 className='popauth-title'>Most Popular Author</h1>
                    <img className='circular--landscape-subcat' src={huberman} alt="Description"></img>
                    <div className='popauth-name-box'>
                        <h1 className='scholar-name'>Andrew Huberman</h1>
                        <h1 className='scholar-name'>@huberman</h1>
                        <h1 className='scholar-name'>1.3m followers</h1>

                    </div>

                </div>
                */}
        </div>
    )
}